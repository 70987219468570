import { Outlet } from "react-router-dom";
import { Container } from "./SharedLayout.styled";
import { Suspense } from "react";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import LoadingSpinner from "./LoadingSpinner";

const SharedLayout = () => {
  return (
    <div>
      <Header />
      <Container>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Container>
      <Footer />
    </div>
  );
};

export default SharedLayout;
