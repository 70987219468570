export const colors = {
  primaryBgColor: "#f7fde2",
  primaryWhite: "#ffffff",
  primaryBlack: "#000000",
  accentGreen: "#89a52a",
  primaryFontColor: "#212529",
  secondaryFontColor: "#333333",
  secondaryGreenColor: "#a2c428",
  footerBgGreenColor: "#a2c428d1",
  copyrightGreenColor: "#556226",
  copyrightAccentColor: "#0a246a",
  linkHoverBg: "#e7f2f9",
};

export const transition = {
  duration: "250ms linear",
};
