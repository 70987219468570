import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { colors, transition } from "styles/common/vars";

export const NavLinksList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 796px;

  li {
    position: relative;
  }
`;

export const AboutUsLinkItem = styled.li`
  &:hover ul,
  &:focus ul {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
`;

export const HeaderNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  padding: 10px;
  vertical-align: middle;

  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 20px;

  transition: background-color ${transition.duration};

  &:hover,
  &:focus,
  &.active {
    background-color: ${colors.primaryWhite};
  }
`;

export const AboutUsList = styled.ul`
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  width: 300px;
  padding: 10px;
  text-transform: uppercase;

  background-color: ${colors.primaryWhite};
  border-radius: 17px;
  box-shadow: 0 20px 50px 0 rgba(26, 105, 149, 0.3);
`;

export const AboutUsNavLink = styled(HeaderNavLink)`
  transition: padding 300ms ease-in-out;

  &:hover,
  &:focus {
    padding: 10px 15px;
  }
`;

export const AboutUsLink = styled.a`
  display: inline-block;
  padding: 10px;

  font-size: 14px;
  color: ${colors.secondaryFontColor};

  border-radius: 20px;

  transition: padding 300ms ease, background-color 300ms ease;

  &:hover,
  &:focus {
    padding: 10px 15px;
    background-color: ${colors.linkHoverBg};
  }
`;
