import styled from "@emotion/styled";
import { colors } from "styles/common/vars";

export const LogoLink = styled.a`
  display: inline-block;
  max-height: 100%;
`;

export const ToggleMenuButton = styled.button`
  position: absolute;
  z-index: 4;
  top: 45px;
  right: 40px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;

export const DropMenuNav = styled.nav`
  position: absolute;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  padding: 80px 0;

  background-color: ${colors.primaryWhite};
  text-align: center;
  font-family: "Source Sans 3", sans-serif;

  transform: translateX(-100%);
  transition: transform 250ms linear;

  &.is-open {
    transform: translateX(0%);
  }
`;

export const NavigationLink = styled.a`
  display: block;
  width: 100%;
  padding: 12px 24px;
  margin: 0;

  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;

  color: ${colors.secondaryFontColor};
`;

export const SubMenuLink = styled.a`
  display: block;
  padding: 10px 0;

  font-size: 16px;
  font-weight: 500;
`;
