import styled from "@emotion/styled";
import { Container } from "components/SharedLayout/SharedLayout.styled";
import { colors } from "styles/common/vars";

export const FooterSection = styled.footer`
  padding: 20px 0 0;

  color: ${colors.primaryWhite};
  background-color: ${colors.footerBgGreenColor};
`;

export const FooterContainer = styled(Container)`
  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row-reverse;

    & > div {
      flex-grow: 1;
    }
  }
`;

export const ContactWrapper = styled.div`
  text-align: left;
  margin: 0 auto 40px;
  padding: 20px 50px;

  @media screen and (min-width: 410px) {
    text-align: center;
  }

  @media screen and (min-width: 1200px) {
    text-align: left;
    padding: 10px 50px;
    margin: 0;
  }
`;

export const ContactTitle = styled.h3`
  margin-bottom: 8px;

  font-size: 23px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    font-size: 28px;
  }
`;

export const ContactMailLink = styled.a`
  display: inline-block;

  font-size: 18px;
  font-weight: 500;
  margin-bottom: 70px;

  @media screen and (min-width: 1200px) {
    margin-bottom: 50px;
  }
`;

export const ContactSocialsList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media screen and (min-width: 1200px) {
    gap: 35px;
    justify-content: left;
  }
`;

export const ContactSocialLink = styled.a`
  width: 32px;
  height: 32px;
  display: block;
  border: 3px solid ${colors.primaryWhite};
  border-radius: 3px;
`;

export const ContactInstagramLink = styled(ContactSocialLink)`
  border: 2px solid transparent;
`;

export const LinkListsWrapper = styled.div`
  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    padding: 46px 60px;
  }
`;

export const LinksList = styled.ul`
  font-family: Ubuntu, sans-serif;
  text-align: center;
  font-size: 20px;

  margin-bottom: 30px;

  li:not(:last-child) {
    margin-bottom: 30px;
  }

  a:hover,
  a:focus {
    color: ${colors.accentGreen};
  }

  @media screen and (min-width: 1200px) {
    font-size: 17px;
    font-weight: 500;
    text-align: left;
    margin: 0;
  }
`;

export const CopyrightWrapper = styled.div`
  padding: 20px 80px;

  font-family: Ubuntu, sans-serif;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    gap: 40px;

    padding: 0px 60px;
  }
`;

export const CopyrightLinksList = styled.ul`
  display: flex;
  justify-content: left;
  flex-wrap: wrap;

  margin-bottom: 20px;

  font-size: 13px;

  column-gap: 30px;

  a {
    text-decoration: underline;
    color: ${colors.copyrightGreenColor};
  }

  a:hover,
  a:focus {
    color: ${colors.copyrightAccentColor};
    text-decoration: none;
  }

  @media screen and (min-width: 410px) {
    justify-content: center;
  }

  @media screen and (min-width: 1200px) {
    justify-content: space-between;
  }
`;

export const Copyright = styled.p`
  font-family: "Ubuntu", sans-serif;
  font-size: 13px;

  text-align: left;

  color: ${colors.copyrightGreenColor};

  @media screen and (min-width: 410px) {
    text-align: center;
  }

  @media screen and (min-width: 1200px) {
    text-align: left;
  }
`;
