export const linksLists = [
  [
    {
      href: "platforma-drezden-plattform-dresden-e-v/",
      text: "footer.aboutUs",
    },
    {
      href: "novini/",
      text: "footer.news",
    },
  ],
  [
    {
      href: "unsere-projekte/",
      text: "footer.ourProjects",
    },
    {
      href: "kalender-2/",
      text: "footer.eventCalendar",
    },
  ],
  [
    {
      href: "pozhertvi/",
      text: "footer.donations",
    },
    {
      href: "library",
      text: "footer.library",
    },
  ],
];

export const copyrightList = [
  { href: "impressum/", text: "Impressum" },
  { href: "satzung/", text: "Satzung" },
  { href: "datenschutzerklarung/", text: "Datenschutz" },
];
