import styled from "@emotion/styled";

export const Container = styled.div`
  position: relative;
  /* outline: 1px solid tomato; */
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: 1200px) {
    width: 1140px;
  }

  @media screen and (min-width: 1400px) {
    width: 1320px;
  }
`;
