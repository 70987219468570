import { Route, Routes } from "react-router-dom";
import SharedLayout from "components/SharedLayout/SharedLayout";
import { lazy } from "react";
const BookDetails = lazy(() => import("pages/BookDetails/BookDetails"));
const Home = lazy(() => import("pages/Home/Home"));

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SharedLayout />}>
        <Route index element={<Home />}></Route>
        <Route path="book-details/:bookId" element={<BookDetails />}></Route>
      </Route>
    </Routes>
  );
};

export default App;
