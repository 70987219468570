import { useTranslation } from "react-i18next";
import { NavigationLink, SubMenuLink } from "./MobileHeader.styled";
import { aboutUsList, navList } from "data/headerLists";

const DropMenuList = () => {
  const { t } = useTranslation();

  return (
    <ul>
      <li>
        <NavigationLink href="https://plattform-dresden.de/">
          {t("header.home")}
        </NavigationLink>
      </li>
      <li>
        <NavigationLink href="https://plattform-dresden.de/platforma-drezden-plattform-dresden-e-v/">
          {t("header.aboutUs.title")}
        </NavigationLink>
        <ul>
          {aboutUsList.map(({ href, text }) => (
            <li key={href}>
              <SubMenuLink href={`https://plattform-dresden.de/${href}`}>
                {t(`header.aboutUs.menu.${text}`)}
              </SubMenuLink>
            </li>
          ))}
        </ul>
      </li>
      {navList.map(({ href, text }) => (
        <li key={href}>
          <NavigationLink
            href={
              href === "library/"
                ? "/"
                : `https://plattform-dresden.de/${href}`
            }
          >
            {t(`header.${text}`)}
          </NavigationLink>
        </li>
      ))}
    </ul>
  );
};

export default DropMenuList;
