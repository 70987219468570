export const localLanguage = {
  async get() {
    try {
      const lang = localStorage.getItem("lang");

      return await JSON.parse(lang);
    } catch (error) {
      console.error(error);
    }
  },
  set(lang) {
    try {
      localStorage.setItem("lang", JSON.stringify(lang));
    } catch (error) {
      console.error(error);
    }
  },
};
