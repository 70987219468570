import styled from "@emotion/styled";
import { colors } from "styles/common/vars";

export const LanguagesList = styled.ul`
  display: flex;
  justify-content: center;
  gap: 7px;
  margin-bottom: ${(props) => (props.footer ? "50px" : "15px")};

  @media screen and (min-width: 1200px) {
    margin-bottom: 15px;
    margin-bottom: 0;
    margin-right: 37px;
    justify-content: right;
  }
`;

export const LanguageBtn = styled.button`
  display: block;

  width: 42px;
  height: 42px;
  margin: 0;
  padding: 7px 10px;

  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;

  color: ${(props) =>
    props.footer ? colors.primaryWhite : colors.secondaryGreenColor};

  border: 2px solid ${colors.primaryWhite};
  background-color: transparent;
  border-radius: 50%;

  transition: color 250ms linear, background-color 250ms linear;

  &:hover,
  &:focus {
    color: ${colors.primaryBlack};
    background-color: ${colors.primaryWhite};
  }

  &.active {
    color: ${colors.primaryBlack};
    background-color: ${colors.primaryWhite};
    width: 45px;
    height: 45px;
    margin: 0 7px;
  }
`;
