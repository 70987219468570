import pageLogoPath from "images/logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { GrClose } from "react-icons/gr";
import { DropMenuNav, LogoLink, ToggleMenuButton } from "./MobileHeader.styled";
import { PageHeader } from "../Header.styled";
import LanguagesBar from "../LanguagesBar";
import DropMenuList from "./DropMenuList";

const MobileHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = window.useState(false);

  return (
    <PageHeader>
      <LogoLink href="/">
        <img src={pageLogoPath} alt="page logo" width={78} />
      </LogoLink>
      <ToggleMenuButton
        type="button"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        {isMenuOpen ? <GrClose size={26} /> : <RxHamburgerMenu size={30} />}
      </ToggleMenuButton>
      <DropMenuNav className={isMenuOpen && "is-open"}>
        <LanguagesBar />
        <DropMenuList />
      </DropMenuNav>
    </PageHeader>
  );
};

export default MobileHeader;
