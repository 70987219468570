import { LanguageBtn, LanguagesList } from "./LanguagesBar.styled";
import { localLanguage } from "utils/localLanguage";

const LanguagesBar = ({ footer }) => {
  // const {useTranslation: {i18n}} = window;
  const { i18n } = window.useTranslation();
  const [currentLang, setCurrentLang] = window.useState("");

  const handleChangeLang = (event) => {
    setCurrentLang(event.target.textContent);
  };

  window.useEffect(() => {
    (async () => {
      const lang = await localLanguage.get();

      if (lang) return setCurrentLang(lang);

      setCurrentLang("de");
    })();
  }, []);

  window.useEffect(() => {
    if (currentLang) {
      localLanguage.set(currentLang);
      i18n.changeLanguage(currentLang);
    }
  }, [currentLang, i18n]);

  return (
    <LanguagesList footer={footer}>
      <li>
        <LanguageBtn
          footer={footer}
          onClick={handleChangeLang}
          className={currentLang === "de" && "active"}
        >
          de
        </LanguageBtn>
      </li>
      <li>
        <LanguageBtn
          footer={footer}
          onClick={handleChangeLang}
          className={currentLang === "ua" && "active"}
        >
          ua
        </LanguageBtn>
      </li>
      <li>
        <LanguageBtn
          footer={footer}
          onClick={handleChangeLang}
          className={currentLang === "en" && "active"}
        >
          en
        </LanguageBtn>
      </li>
    </LanguagesList>
  );
};

export default LanguagesBar;
