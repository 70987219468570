import MobileHeader from "./MobileHeader/MobileHeader";
import pageLogoPath from "images/logo.png";
import useWindowDimensions from "hooks/useWindowDimensions";
import { HeaderWrapper, PageHeader } from "./Header.styled";
import { Container } from "components/SharedLayout/SharedLayout.styled";

import LanguagesBar from "./LanguagesBar";
import HeaderNav from "./HeaderNav";

const Header = () => {
  const { isDesktop } = useWindowDimensions();

  return isDesktop ? (
    <PageHeader>
      <Container>
        <HeaderWrapper>
          <a href="https://plattform-dresden.de/">
            <img src={pageLogoPath} alt="page logo" width={118} />
          </a>
          <HeaderNav />
          <LanguagesBar />
        </HeaderWrapper>
      </Container>
    </PageHeader>
  ) : (
    <MobileHeader />
  );
};

export default Header;
