export const navList = [
  {
    href: "unsere-projekte/",
    text: "ourProjects",
  },
  {
    href: "kalender-2/",
    text: "eventCalendar",
  },
  {
    href: "pozhertvi/",
    text: "donations",
  },
  {
    href: "library/",
    text: "library",
  },
];

export const aboutUsList = [
  {
    href: "platforma-drezden-plattform-dresden-e-v/",
    text: "platform",
  },
  {
    href: "kooperationspartner/",
    text: "ourPartners",
  },
  {
    href: "gefordert-durch/",
    text: "fundedBy",
  },
  {
    href: "novini/",
    text: "news",
  },
];
