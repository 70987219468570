import { HiHome } from "react-icons/hi";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { colors } from "styles/common/vars";
import { aboutUsList, navList } from "data/headerLists";
import {
  AboutUsLink,
  AboutUsLinkItem,
  AboutUsList,
  AboutUsNavLink,
  HeaderNavLink,
  NavLinksList,
} from "./HeaderNav.styled";

const HeaderNav = () => {
  const { t } = window.useTranslation();

  return (
    <nav>
      <NavLinksList>
        <li>
          <HeaderNavLink to="https://plattform-dresden.de/">
            <HiHome size={16} />
          </HeaderNavLink>
        </li>
        <AboutUsLinkItem>
          <HeaderNavLink to="https://plattform-dresden.de/platforma-drezden-plattform-dresden-e-v/">
            {t("header.aboutUs.title")}
            <IoIosArrowDown size={20} color={colors.accentGreen} />
          </HeaderNavLink>
          <AboutUsList>
            <AboutUsNavLink to="https://plattform-dresden.de/platforma-drezden-plattform-dresden-e-v/">
              {t("header.aboutUs.title")}
              <IoIosArrowUp size={20} color={colors.accentGreen} />
            </AboutUsNavLink>
            {aboutUsList.map(({ href, text }) => (
              <li key={href}>
                <AboutUsLink href={`https://plattform-dresden.de/${href}`}>
                  {t(`header.aboutUs.menu.${text}`)}
                </AboutUsLink>
              </li>
            ))}
          </AboutUsList>
        </AboutUsLinkItem>
        {navList.map(({ href, text }) => (
          <li key={href}>
            <HeaderNavLink
              to={
                href === "library/"
                  ? "/"
                  : `https://plattform-dresden.de/${href}`
              }
            >
              {t(`header.${text}`)}
            </HeaderNavLink>
          </li>
        ))}
      </NavLinksList>
    </nav>
  );
};

export default HeaderNav;
