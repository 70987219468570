import { RotatingLines } from "react-loader-spinner";
import { SpinnerWrapper } from "./LoadingSpinner.styled";
import { colors } from "styles/common/vars";

const LoadingSpinner = () => {
  return (
    <SpinnerWrapper>
      <RotatingLines strokeColor={colors.accentGreen} width="48px" />
    </SpinnerWrapper>
  );
};

export default LoadingSpinner;
