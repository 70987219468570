import LanguagesBar from "components/Header/LanguagesBar";
import {
  ContactInstagramLink,
  ContactMailLink,
  ContactSocialLink,
  ContactSocialsList,
  ContactTitle,
  ContactWrapper,
  Copyright,
  CopyrightLinksList,
  CopyrightWrapper,
  FooterContainer,
  FooterSection,
  LinkListsWrapper,
  LinksList,
} from "./Footer.styled";
import {
  BiLogoTelegram,
  BiLogoFacebook,
  BiLogoInstagram,
} from "react-icons/bi";
import { linksLists, copyrightList } from "data/footerLists";

const Footer = () => {
  const [t] = window.useTranslation();

  return (
    <FooterSection>
      <FooterContainer>
        <ContactWrapper>
          <ContactTitle>{t("footer.contacts")}</ContactTitle>
          <ContactMailLink href="mailto:info@platform-dresden.de">
            info@platform-dresden.de
          </ContactMailLink>
          <ContactSocialsList>
            <li>
              <ContactSocialLink href="https://t.me/ukrzentrum">
                <BiLogoTelegram size={28} />
              </ContactSocialLink>
            </li>
            <li>
              <ContactSocialLink href="https://facebook.com/plattformdresden/">
                <BiLogoFacebook size={34} />
              </ContactSocialLink>
            </li>
            <li>
              <ContactInstagramLink href="https://www.instagram.com/ua.basedd">
                <BiLogoInstagram size={38} attr={{ viewBox: "3 3 22 22" }} />
              </ContactInstagramLink>
            </li>
          </ContactSocialsList>
        </ContactWrapper>
        <div>
          <LanguagesBar footer="true" />
          <LinkListsWrapper>
            {linksLists.map((subList, index) => (
              <LinksList key={index}>
                {subList.map(({ href, text }) => (
                  <li key={href}>
                    <a
                      href={
                        href === "library"
                          ? "/"
                          : `https://plattform-dresden.de/${href}`
                      }
                    >
                      {t(text)}
                    </a>
                  </li>
                ))}
              </LinksList>
            ))}
          </LinkListsWrapper>
          <CopyrightWrapper>
            <CopyrightLinksList>
              {copyrightList.map(({ href, text }) => (
                <li key={href}>
                  <a href={`https://plattform-dresden.de/${href}`}>{text}</a>
                </li>
              ))}
            </CopyrightLinksList>
            <Copyright>&#169; 2022 Plattform Dresden e.V.</Copyright>
          </CopyrightWrapper>
        </div>
      </FooterContainer>
    </FooterSection>
  );
};

export default Footer;
