import styled from "@emotion/styled";
import { colors } from "styles/common/vars";

export const PageHeader = styled.header`
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 120px;
  padding: 30px 40px;

  background-color: ${colors.primaryBgColor};

  @media screen and (min-width: 1200px) {
    height: 140px;
    position: static;
    padding: 25px 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0px 12px;
`;
